import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { FaHome as HomeIcon, FaUser as UserIcon } from "react-icons/fa"
import { Link } from "gatsby"
import { rhythm } from "utils/typography"

const verticalNavStyle = () => css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const bottomBorderStyle = () => css`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #f0f0f2;
  padding-bottom: ${rhythm(0.3)};
`

const Nav = styled.nav`
  margin-top: ${props => (props.horizontal ? rhythm(0.3) : 0)};

  ${props => (props.horizontal ? bottomBorderStyle : verticalNavStyle)}
`

const HorizontalNavLink = styled(Link)`
  margin-right: ${rhythm(0.6)};
  display: inline;
  margin-bottom: 0;
`

const VerticalNavLink = styled(Link)`
  margin-right: 0;
  display: block;
  margin-bottom: ${rhythm(0.3)};
`

const NavLink = props => {
  return props.horizontal ? (
    <HorizontalNavLink to={props.to}>{props.children}</HorizontalNavLink>
  ) : (
    <VerticalNavLink to={props.to}>{props.children}</VerticalNavLink>
  )
}

const commonIconStyle = () => css`
  display: inline-block;
  width: 1em;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
`

const StyledHomeIcon = styled(HomeIcon)`
  ${commonIconStyle}
`
const StyledUserIcon = styled(UserIcon)`
  ${commonIconStyle}
`

const NavItem = styled.div`
  display: ${props => (props.horizontal ? `inline` : `flex`)};
`

const Navigation = props => (
  <Nav {...props}>
    <div style={{ textAlign: `left` }}>
      <NavItem {...props}>
        <NavLink to="/" {...props}>
          <StyledHomeIcon /> Blog
        </NavLink>
      </NavItem>
      <NavItem {...props}>
        <NavLink to="/about/" {...props}>
          <StyledUserIcon /> About
        </NavLink>
      </NavItem>
      <NavItem {...props}>
        <NavLink to="/mentorship/" {...props}>
          <StyledUserIcon /> Mentorship
        </NavLink>
      </NavItem>
    </div>
  </Nav>
)

export default Navigation
