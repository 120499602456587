import merge from "deepmerge"
import { toTheme } from "@theme-ui/typography"
import { theme as typographyTheme, rhythm } from "./typography"

const shadowDarkBase = `19,18,23`
const shadowDarkFlares = `0,0,0`

interface IColorShades {
  [shade: number]: string
}

interface IPalette {
  [color: string]: IColorShades
}

const palette: IPalette = {
  purple: {
    90: "#362066",
    80: "#452475",
    70: "#542c85",
    60: "#663399",
    50: "#8a4baf",
    40: "#b17acc",
    30: "#d9bae8",
    20: "#f1defa",
    10: "#f6edfa",
    5: "#fcfaff",
  },
  orange: {
    90: "#db3a00",
    80: "#e65800",
    70: "#f67300",
    60: "#fb8400",
    50: "#ffb238",
    40: "#ffd280",
    30: "#ffe4a1",
    20: "#ffedbf",
    10: "#fff4db",
    5: "#fffcf7",
  },
  yellow: {
    90: "#8a6534",
    80: "#bf9141",
    70: "#e3a617",
    60: "#fec21e",
    50: "#fed038",
    40: "#ffdf37",
    30: "#ffeb99",
    20: "#fff2a8",
    10: "#fff5bf",
    5: "#fffdf7",
  },
  red: {
    90: "#b80000",
    80: "#ce0009",
    70: "#da0013",
    60: "#ec1818",
    50: "#fa2915",
    40: "#ff5a54",
    30: "#ff8885",
    20: "#ffbab8",
    10: "#fde7e7",
    5: "#fffafa",
  },
  magenta: {
    90: "#690147",
    80: "#7d0e59",
    70: "#940159",
    60: "#a6026a",
    50: "#bc027f",
    40: "#d459ab",
    30: "#e899ce",
    20: "#f2c4e3",
    10: "#ffe6f6",
    5: "#fffafd",
  },
  blue: {
    90: "#004ca3",
    80: "#006ac1",
    70: "#047bd3",
    60: "#0e8de6",
    50: "#0d96f2",
    40: "#3fa9f5",
    30: "#63b8f6",
    20: "#90cdf9",
    10: "#dbf0ff",
    5: "#f5fcff",
  },
  teal: {
    90: "#008577",
    80: "#10a39e",
    70: "#00bdb6",
    60: "#2de3da",
    50: "#05f7f4",
    40: "#73fff7",
    30: "#a6fffa",
    20: "#ccfffc",
    10: "#dcfffd",
    5: "#f7ffff",
  },
  green: {
    90: "#006500",
    80: "#088413",
    70: "#1d9520",
    60: "#2ca72c",
    50: "#37b635",
    40: "#59c156",
    30: "#79cd75",
    20: "#a1da9e",
    10: "#def5dc",
    5: "#f7fdf7",
  },
  grey: {
    90: "#232129",
    80: "#36313d",
    70: "#48434f",
    60: "#635e69",
    50: "#78757a",
    40: "#b7b5bd",
    30: "#d9d7e0",
    20: "#f0f0f2",
    10: "#f5f5f5",
    5: "#fbfbfb",
  },
  white: "#ffffff",
  black: "#000000",
}
const blackRGB = "35, 33, 41" // grey.90
const whiteRGB = "255, 255, 255"

interface IStringCssProps {
  [name: string]: string
}

interface ITransition {
  default: string
  curve: IStringCssProps
  speed: IStringCssProps
}

const transition: ITransition = {
  default: `250ms cubic-bezier(0.4, 0, 0.2, 1)`,
  curve: {
    default: `cubic-bezier(0.4, 0, 0.2, 1)`,
    fastOutLinearIn: `cubic-bezier(0.4, 0, 1, 1)`,
  },
  speed: {
    faster: `50ms`,
    fast: `100ms`,
    default: `250ms`,
    slow: `500ms`,
    slower: `1000ms`,
  },
}

interface IColors {
  newsletter: IStringCssProps
  textMuted: string
  warning: string
  background: string
  primary: string
  primaryShades: {
    [shade: number]: string
  }
  secondary: string
  blackFade: IColorShades
  whiteFade: IColorShades
  palette: IPalette
  input: IStringCssProps
  text: {
    primary: string
    placeholder: string
  }
  ui: {
    border: string
  }
}

const hex2rgba = (hex: string, alpha: number) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16))
  return `rgba(${r},${g},${b},${alpha})`
}

const primaryColor = `#007acc`

const colors: IColors = {
  newsletter: {
    background: `white`,
    border: `#f5f5f5`,
    heading: `#48434f`,
    stripeColorA: `#ff5a54`,
    stripeColorB: `#3fa9f5`,
  },
  textMuted: `#78757a`,
  warning: `#da0013`,
  background: "#fff",
  primary: primaryColor,
  primaryShades: {
    20: hex2rgba(primaryColor, 0.2),
  },
  secondary: `#da0013`,

  blackFade: {
    90: "rgba(" + blackRGB + ", 0.9)",
    80: "rgba(" + blackRGB + ", 0.8)",
    70: "rgba(" + blackRGB + ", 0.7)",
    60: "rgba(" + blackRGB + ", 0.6)",
    50: "rgba(" + blackRGB + ", 0.5)",
    40: "rgba(" + blackRGB + ", 0.4)",
    30: "rgba(" + blackRGB + ", 0.3)",
    20: "rgba(" + blackRGB + ", 0.2)",
    10: "rgba(" + blackRGB + ", 0.1)",
    5: "rgba(" + blackRGB + ", 0.05)",
  },
  whiteFade: {
    90: "rgba(" + whiteRGB + ", 0.9)",
    80: "rgba(" + whiteRGB + ", 0.8)",
    70: "rgba(" + whiteRGB + ", 0.7)",
    60: "rgba(" + whiteRGB + ", 0.6)",
    50: "rgba(" + whiteRGB + ", 0.5)",
    40: "rgba(" + whiteRGB + ", 0.4)",
    30: "rgba(" + whiteRGB + ", 0.3)",
    20: "rgba(" + whiteRGB + ", 0.2)",
    10: "rgba(" + whiteRGB + ", 0.1)",
    5: "rgba(" + whiteRGB + ", 0.05)",
  },
  palette,
  input: {
    border: palette.grey[30],
    focusBorder: palette.orange[40],
    focusBoxShadow: palette.orange[20],
  },
  text: {
    primary: "#000",
    placeholder: palette.grey[40],
  },
  ui: {
    border: palette.grey[20],
  },
}

interface ILineHeights {
  solid: number
  dense: number
  loose: number
}

const lineHeights: ILineHeights = {
  solid: 1,
  dense: 1.25,
  loose: 1.75,
}

export interface ITheme {
  colors: IColors
  lineHeights: ILineHeights
  shadows: IStringCssProps
  radii: string[]
  buttons: any
  forms: any
  styles: any
  space: string[]
  fonts: {
    body?: string
    heading?: string
  }
  fontSizes: string[]
  fontWeights: {
    body?: string
    heading?: string
  }
}

const localTheme: ITheme = {
  colors,
  lineHeights,
  shadows: {
    dialog: `0px 4px 16px rgba(${shadowDarkBase}, 0.08), 0px 8px 24px rgba(${shadowDarkFlares}, 0.16)`,
    floating: `0px 2px 4px rgba(${shadowDarkBase}, 0.08), 0px 4px 8px rgba(${shadowDarkFlares}, 0.16)`,
    overlay: `0px 4px 8px rgba(${shadowDarkBase}, 0.08), 0px 8px 16px rgba(${shadowDarkFlares}, 0.16)`,
    raised: `0px 1px 2px rgba(${shadowDarkBase}, 0.08), 0px 2px 4px rgba(${shadowDarkFlares}, 0.08)`,
  },
  radii: [`0`, `2px`, `4px`, `8px`, `16px`],
  buttons: {
    primary: {
      borderRadius: 2,
      borderWidth: 1,
      color: "background",
      bg: "primary",
      "&:hover": {
        bg: palette.blue[90],
      },
      cursor: `pointer`,
      fontFamily: `heading`,
      fontWeight: `bold`,
      fontSize: 1,
      lineHeight: `solid`,
      textDecoration: `none`,
      whiteSpace: `nowrap`,
      px: 3,
      height: `36px`,
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: "bold",
    },
    input: {
      backgroundColor: palette.white,
      display: `block`,
      fontSize: 1,
      fontWeight: `body`,
      lineHeight: `2.25rem`,
      py: 0,
      px: 2,
      verticalAlign: `middle`,
      width: `100%`,
      border: `1px solid ${colors.input.border}`,
      borderRadius: 2,
      transition: `box-shadow ${transition.speed.default} ${transition.curve.default}`,
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
      "::placeholder": {
        color: colors.text.placeholder,
        opacity: 1,
      },
      "&:disabled": {
        cursor: `not-allowed`,
        opacity: `0.5`,
      },
    },
    select: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    textarea: {
      borderColor: "gray",
      "&:focus": {
        borderColor: "primary",
        boxShadow: (t) => `0 0 0 2px ${t.colors.primary}`,
        outline: "none",
      },
    },
    slider: {
      bg: "muted",
    },
  },
  styles: {
    root: {
      pre: {
        borderRadius: 0,
      },
      "a.gatsby-resp-image-link": {
        boxShadow: `none`,
      },
      a: {
        boxShadow: "0 1px 0 0 currentColor",
        color: "primary",
        textDecoration: "none",
      },
      "a:hover,a:active": {
        textDecoration: "none",
        boxShadow: "none",
      },
      "a.anchor": {
        textDecoration: "none",
        boxShadow: "none",
      },
      body: {
        // copy-pasted from medium.com css
        ["-webkit-font-smoothing"]: "antialiased",
        textRendering: "optimizeLegibility",
        color: "rgba(41, 41, 41, 1)",
      },
      ["ol,ul"]: {
        // override https://github.com/KyleAMathews/typography.js/blob/master/packages/typography-theme-sutro/src/index.js
        marginLeft: rhythm(1),
      },
      ["h1,h2,h3,h4,h5,h6"]: {
        // override https://github.com/KyleAMathews/typography.js/blob/master/packages/typography-theme-sutro/src/index.js
        marginTop: rhythm(1.5),
        marginBottom: 0,
      },
      ["p,ul,ol"]: {
        marginBottom: 0,
        marginTop: rhythm(0.5),
      },
    },
  },
  space: [],
  fonts: {},
  fontSizes: [],
  fontWeights: {},
}

const uiTypographyTheme = toTheme(typographyTheme)
const resultTheme: ITheme = merge(uiTypographyTheme, localTheme)

resultTheme.space = [`0rem`, `0.25rem`, `0.5rem`, `0.75rem`, `1rem`, `1.25rem`, `1.5rem`, `2rem`, `2.5rem`, `3rem`]
resultTheme.fontSizes = [`14px`, `18px`, `20px`, `22px`, `24px`, `28px`]
console.log(`theme:`, resultTheme)
export default resultTheme
