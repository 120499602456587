import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "@emotion/styled"
import { css } from "@emotion/react"
import { Row, Col } from "react-flexbox-grid"
import {
  FaTwitter as TwitterIcon,
  FaLinkedinIn as LinkedInIcon,
  FaFacebookF as FacebookIcon,
  FaGithub as GitHubIcon,
  FaStackOverflow as StackOverflowIcon,
} from "react-icons/fa"

import { rhythm } from "utils/typography"
import Nav from "./nav"

const dataQuery = graphql`
  query ColumnBioQuery {
    avatar: file(absolutePath: { regex: "/denis-isaev-photo.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 160, height: 160, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
      }
    }
    site {
      siteMetadata {
        author {
          name
          summary
        }
        social {
          twitter
          github
          facebook
          stackoverflow
          linkedin
        }
      }
    }
  }
`

const ColumnBioSummary = styled.p`
  font-size: 0.85rem;
  margin-bottom: 0;
  margin-top: ${rhythm(0.7)};
  padding: 0 ${rhythm(0.3)};
`

const ColumnImageContainer = styled.div`
  flex-basis: 160px;
  max-width: 80%;
  margin-top: ${rhythm(1)};
`

const columnImageWrapperStyle = {
  margin: 0,
  maxWidth: `160px`,
  height: `160px`,
}

const columnImageStyle = {
  borderRadius: `50%`,
}

const BottomBorderedContainer = styled.div`
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: #f0f0f2;
  padding-bottom: ${rhythm(0.3)};
  margin-bottom: ${rhythm(0.3)};
`

const BioIconsList = styled.ul`
  list-style: none;
  margin-top: ${rhythm(0.7)};
  margin-bottom: ${rhythm(0.7)};
`

const IconsListItem = styled.li`
  &:not(:last-child) {
    margin-right: ${rhythm(0.4)};
  }

  display: inline;
`

const commonIconStyle = () => css`
  text-align: center;
  color: white;
  width: 100%;
  vertical-align: -0.25em;
`

const IconContainer = styled.span`
  display: inline-block;
  width: 2em;
  height: 2em;

  background: rgba(34, 162, 201, 1);
  border-radius: 50%;
`

const StyledTwitterIcon = styled(TwitterIcon)`
  ${commonIconStyle}
`
const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${commonIconStyle}
`
const StyledFacebookIcon = styled(FacebookIcon)`
  ${commonIconStyle}
`
const StyledGitHubIcon = styled(GitHubIcon)`
  ${commonIconStyle}
`
const StyledStackOverflowIcon = styled(StackOverflowIcon)`
  ${commonIconStyle}
`

const RawLink = styled.a`
  text-decoration: none;
  box-shadow: none;
`

const IconLink = (props) => (
  <RawLink href={props.href} target="_blank" rel="nofollow noreferrer noopener">
    {props.children}
  </RawLink>
)

const StyledNav = styled(Nav)`
  font-size: 0.85rem;
`

const renderColumnBio = (data) => {
  const { author, social } = data.site.siteMetadata
  return (
    <BottomBorderedContainer>
      <Row center="xs">
        <ColumnImageContainer>
          <GatsbyImage
            image={getImage(data.avatar)}
            alt={author.name}
            style={columnImageWrapperStyle}
            imgStyle={columnImageStyle}
          />
        </ColumnImageContainer>
      </Row>
      <Row center="xs">
        <Col xs={12}>
          <ColumnBioSummary>
            Hi, I'm Denis. If you're looking to reach out to me, <a href="https://disaev.me/about/#contact-channels">here</a> are the ways. Also, I'm available for <a href="https://disaev.me/mentorship/">mentorship and coaching</a>.
          </ColumnBioSummary>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12}>
          <BioIconsList>
            <IconsListItem>
              <IconLink href={`https://www.linkedin.com/in/${social.linkedin}/?locale=en_US`}>
                <IconContainer>
                  <StyledLinkedInIcon />
                </IconContainer>
              </IconLink>
            </IconsListItem>
            <IconsListItem>
              <IconLink href={`https://github.com/${social.github}`}>
                <IconContainer>
                  <StyledGitHubIcon />
                </IconContainer>
              </IconLink>
            </IconsListItem>
            <IconsListItem>
              <IconLink href={`https://twitter.com/${social.twitter}`}>
                <IconContainer>
                  <StyledTwitterIcon />
                </IconContainer>
              </IconLink>
            </IconsListItem>
            <IconsListItem>
              <IconLink href={`https://facebook.com/${social.facebook}`}>
                <IconContainer>
                  <StyledFacebookIcon />
                </IconContainer>
              </IconLink>
            </IconsListItem>
            <IconsListItem>
              <IconLink href={`https://stackoverflow.com/users/${social.stackoverflow}`}>
                <IconContainer>
                  <StyledStackOverflowIcon />
                </IconContainer>
              </IconLink>
            </IconsListItem>
          </BioIconsList>
        </Col>
      </Row>
      <Row center="xs">
        <Col xs={12}>
          <StyledNav vertical />
        </Col>
      </Row>
    </BottomBorderedContainer>
  )
}

const ColumnBio = () => <StaticQuery query={dataQuery} render={renderColumnBio} />

export default ColumnBio
