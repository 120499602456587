import React from "react"
import styled from "@emotion/styled"
import { FaAngleDoubleUp as UpIcon } from "react-icons/fa"
import ScrollToTop from "react-scroll-up"

const BackToTopIcon = styled(UpIcon)`
  width: 3em;
  height: auto;
  color: rgba(34, 162, 201, 0.3);

  &:hover {
    color: rgba(34, 162, 201, 1);
  }
`

const BackToTop = () => (
  <ScrollToTop
    showUnder={160}
    style={{ zIndex: 999 }}
    onClick={() => window.history.replaceState({}, "", window.location.pathname)} // remove hash
  >
    <BackToTopIcon />
  </ScrollToTop>
)

export default BackToTop
