import Typography from "typography"
import Theme from "typography-theme-sutro"
import CodePlugin from "typography-plugin-code"

// https://github.com/KyleAMathews/typography.js/blob/master/packages/typography-plugin-code/src/index.js
Theme.plugins = [new CodePlugin()]

// we self-host fonts
delete Theme.googleFonts

// get from https://github.com/KyleAMathews/typography.js/blob/master/packages/typography-theme-alton/src/index.js
Theme.headerFontFamily = ["Open Sans", "sans-serif"]
Theme.bodyFontFamily = ["Charter", "sans-serif"]
Theme.bodyColor = "black"
Theme.bodyWeight = 400

// like in medium.com
Theme.baseFontSize = "20px"
Theme.baseLineHeight = 1.6

const typography = new Typography(Theme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
export const theme = Theme
