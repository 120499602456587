import React from "react"
import { Grid, Row, Col } from "react-flexbox-grid"
import styled from "@emotion/styled"
import { ThemeProvider } from "theme-ui"

import ColumnBio from "./column-bio"
import BackToTop from "./back-to-top"
import Nav from "./nav"

import "./prismjs/theme.css"
import "./prismjs/features.css"
import "./basecss/custom.css"

import mediaQuery from "utils/mediaQuery"
import { rhythm } from "utils/typography"
import theme from "utils/theme"

const RightColumn = styled(Col)`
  ${mediaQuery.minMedium} {
    border-left-width: 1px;
    border-left-style: solid;
    border-color: #f0f0f2;
  }
  ${mediaQuery.maxMedium} {
    display: none;
  }
`

const Container = styled(Grid)`
  padding: 0 1.5vw;
`

const SiteRow = styled(Row)``

const ContentColumn = styled(Col)`
  padding: 0 5vw;
`

const Footer = styled.footer`
  border-top-width: 1px;
  border-top-style: solid;
  border-color: #f0f0f2;
  margin-top: ${rhythm(1)};
  padding-top ${rhythm(0.2)};
`

const Layout = ({ children, sidebar }) => {
  return (
    <ThemeProvider theme={theme}>
      <Container fluid>
        <SiteRow>
          <ContentColumn xs={12} sm={12} md={8} lg={7} lgOffset={1} xl={5} xlOffset={3}>
            <header>
              <Nav horizontal />
            </header>
            <main id="content">{children}</main>
            <Footer>© {new Date().getFullYear()}</Footer>
          </ContentColumn>
          <RightColumn xs={false} sm={false} md={4} lg={3} lgOffset={1} xl={2} xlOffset={0}>
            <ColumnBio />
            {sidebar}
          </RightColumn>
        </SiteRow>
        <BackToTop />
      </Container>
    </ThemeProvider>
  )
}

export default Layout
